import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorElizabeth as author } from 'data/authors';
import Link from 'components/ui/link';
import img from 'img/blog/covers/email_marketing.png';
import img1 from 'img/blog/best-ux-practices-to-built-email-marketing-lists/blog.png';
import img2 from 'img/blog/best-ux-practices-to-built-email-marketing-lists/popup.png';
import img3 from 'img/blog/best-ux-practices-to-built-email-marketing-lists/landingi.png';
import img4 from 'img/blog/best-ux-practices-to-built-email-marketing-lists/mail.png';
import img5 from 'img/blog/best-ux-practices-to-built-email-marketing-lists/kamilemail.png';
import img6 from 'img/blog/best-ux-practices-to-built-email-marketing-lists/heatmap.png';

const Content = () => {
  return (
    <Post>
      <p>
        Running an eCommerce can be exciting. However, you’ll need to pay attention to every detail
        – from the core of your business, to the most tedious details – that includes the user
        experience (UX). The UX design determines whether or not customers will want to visit and
        buy from you again.{' '}
      </p>
      <p>
        That’s where email marketing comes in. Building a great email list is important, but so is
        ensuring that it doesn’t hurt your UX design. Here are 7 ways you can integrate good email
        marketing with your UX design:
      </p>
      <img src={img1} alt="LiveSession Blog - screenshot" title="LiveSession Blog page" />
      <h2>1. Static Opt-In Form</h2>
      <p>
        “An opt-in form is the most basic form in eCommerce email marketing,” says Richard Wells, a
        marketing manager at{' '}
        <Link
          href="https://uktopwriters.com/review-ukwritings/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          UK Writings
        </Link>{' '}
        and{' '}
        <Link
          href="https://ukservicesreviews.com/oxessays/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          OX Essays
        </Link>
        . “This form helps you keep your UX design smooth and clean by sticking on either the right
        side of a page, or in the bottom footer. And, if desired, you can hide the form with a
        sticky tab, so that it doesn’t interfere with your UX. However, you should still make it to
        where customers can still see the form.”
      </p>
      <img src={img2} alt="LiveSession Blog - screenshot - popup" title="Static Opt-In Form" />
      <h2>2. Popups (When Used Properly)</h2>
      <p>
        While popups may get a bad rap for being irritating, there are ways to make them integrate
        perfectly with UX without frustrating users. Here are a few different popups to know:{' '}
      </p>
      <ul>
        <li>
          <i>Time-based popups</i> appear after a user has been on a page for a certain number of
          seconds. Keep in mind: these types of popups are the most disruptive for both users and
          the UX design.
        </li>
        <li>
          <i>Scroll-based popups</i> are less irritating for users (since they appear after a
          certain amount of the page has been scrolled), but they can still disrupt your UX design.
        </li>
        <li>
          <i>Exit-Intent popups</i> are the best option – These only appear when the user is heading
          to the X on the side of your tab; so, it doesn’t break UX.
        </li>
      </ul>
      <p>So, to ensure that you’re enabling popups properly, check out these pro tips: </p>
      <ul>
        <li>Use only one type of popup, so that you don’t confuse users.</li>
        <li>
          Disable popups for users who have already signed up, or had opted-out or receiving popups.
        </li>
        <li>The images, background, and tone of your popups must match your brand.</li>
      </ul>
      <h2>3. Sign-Up Forms That Are Interactive</h2>
      <p>
        Sign-up forms should make people smile, not bored. Therefore, an interactive sign-up form
        can help make the experience more fun for your customers. People like playing games; so, an
        interactive sign-up form is a fun way to entice users to take action. Best of all, gamified
        sign-up forms makes your UX design stand out more, since you can customize how the forms
        will look once they’re live. (These forms are especially ideal if you’re holding a giveaway
        or offering special discounts.)
      </p>
      <img src={img3} alt="Landingi - screenshot" title="Sign-Up Forms That Are Interactive" />
      <h2>4. Landing Pages</h2>
      <p>
        “Integrating a landing page into your email marketing strategy is helpful, if you’re doing
        events like a contest or sale,” says Michael Turner, a business writer at{' '}
        <Link
          href="https://revieweal.com/top-au-writing-services/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Revieweal
        </Link>{' '}
        and{' '}
        <Link
          href="https://bestbritishessays.com/stateofwriting-com-review/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          State of writing
        </Link>
        .{' '}
      </p>
      <p>
        “While landing pages won’t work well for your entire site, they’re still good for showing
        off special events happening in your brand without disrupting the UX design. All you have to
        do is keep landing pages streamlined, and not have them distracting to your customers.”
      </p>
      <p>
        When it comes to any kind of marketing, the trick to success is getting people through the
        funnel with the minimal number of clicks. The more clicks to be had and the more hoops your
        readers have to go through, the more reluctant they will be to continue. Linking landing
        pages in this way is a great way to cut the distance and get people engaged in your mission.
      </p>
      <h2>5. Optimize Your Email Basics</h2>
      <p>
        {' '}
        When it comes to email marketing, and content marketing in general, there’s no doubt that
        there are some hard and fast rules that you must remember to ensure you’re producing
        high-quality content, but these are rules that are easy to forget when you’re focusing on
        all the other important things you need to do.
      </p>
      <p>
        For example, you should make sure the subject lines of your emails are short, eye-catching,
        and accurate (not clickbait-y), and that any calls to action within your emails are clear
        and concise, as well as not having more than one CTA per email.
      </p>
      <p>
        UX stands for ‘user experience’, and all these aspects and elements of your emails come
        together to create the overall user experience. Have a misleading subject line in your email
        and it’s going to bring your experience down. Some important considerations to then think
        about include:
      </p>
      <ul>
        <li>Short and accurate subject lines</li>
        <li>Interesting yet punchy email content</li>
        <li>Bold and simple CTA</li>
        <li>Alt-tag your images</li>
        <li>Don’t overuse images that will make your emails slow</li>
        <li>Optimize your emails for mobile devices and tablets</li>
        <li>Make your emails easily shareable via social media using links and buttons</li>
      </ul>
      <img src={img4} alt="LiveSession Email - summary" title="Optimize Your Email Basics" />
      <h2>6. Use Email Personalization</h2>
      <p>
        Nowadays, people expect emails to be personalized in the sense that you’re using their names
        in the headers, and only sending them emails that are relevant to them. Imagine if you’re a
        40-year-old male and you’re receiving email content from an eCommerce store about
        soon-to-be-mothers’ clothes. It doesn’t work and this kind of mass emailing is only going to
        put people off opening your emails in the future.
      </p>
      <p>
        Instead, you need to think about gathering data about your email subscribers and then
        segmenting your email list by certain demographics, such as age, gender, income, sales and
        purchase history, geographical location, and so on.
      </p>
      <p>
        This way, you’ll be able to create effective, targeted emails that are relevant to the
        people opening them and have a personalized touch that’s going to make them want to continue
        opening your emails in the future.
      </p>
      <p>And, at the end of the day, it’s always nice opening a friendly email.</p>
      <img src={img5} alt="LiveSession Email - opinion" title="Use Email Personalization" />
      <h2>7. Use Feedback</h2>
      <p>
        Whether or not you agree that the customer is always right, the customer will tend to know
        whether they’re enjoying the reading of an email or not, which is why it’s always a good
        idea to get feedback.
      </p>
      <p>
        There are multiple ways you can do this. You could add a link at the bottom of your email
        asking people to fill out a survey or leaving a comment on a page if they had any thoughts
        they had to share. You could be more proactive and run a survey on your entire business,
        offering discounts on the chance to win a prize once people have filled out their answers.
      </p>
      <p>
        If you are taking this approach, then you need to make sure you’re precise with your
        information and the way you go about getting it. For example, most people are only ever
        going to leave a review for the following reasons:
      </p>
      <ul>
        <li>There’s an incentive to be gained</li>
        <li>They’re exceptionally, unbelievably happy with their experience</li>
        <li>They’re exceptionally unhappy with their experience</li>
      </ul>
      <p>
        This can make your results a little biased if you’re passively collecting data, because
        you’re only ever going to see the opposite ends of the spectrum, but this should be obvious
        when looking through your results. The best idea is to step back and get a glimpse at the
        big picture of how people feel about your emails.
      </p>
      <p>
        You can also use statistics and analytics to judge your email content. For example, if you
        have a really high open rate, then you know your subject lines are working, but if people
        are jumping out of your emails again within a few seconds, then you need to make your emails
        more attractive, especially with the opening sentences.
      </p>
      <p>This leads us nicely onto our bonus tip!</p>
      <img src={img6} alt="LiveSession Blog heatmap from App" title="Use Feedback" />
      <h2>BONUS TIP: Use Click And Heat Maps</h2>
      <p>
        Heat maps are also beneficial for allowing to see user behavior on your page, along with
        your website’s performance. Heatmapping consists of click maps, among other types of maps.
      </p>
      <p>
        Click maps show you where visitors are clicking their mouse on desktop devices, or where
        they’re tapping on mobile devices (also known as touch heatmaps). In that case, these types
        of maps are color-coded (normally red, orange, and yellow) to show where users are clicking
        and tapping the most. And, in terms of{' '}
        <Link href="/" target="_blank" rel="noopener noreferrer">
          email marketing
        </Link>
        , you’ll need to see where people are clicking and or tapping, so that you can integrate an
        email list into your UX design.
      </p>
      <h2>Conclusion</h2>
      <p>
        So, there you have it! These 4 ways (including the bonus tip) can ensure that your email
        marketing strategies can be incorporated seamlessly with your UX design. As you can see,
        both email marketing and UX design can go hand-in-hand, as you run an eCommerce store.
      </p>
      <p>
        While this process can take time, it’s still important to play your cards right. As you keep
        these techniques in mind, you’ll create a great experience for your customers, and keep them
        happy from beginning to end, thus helping you build an amazing customer base.
      </p>
      <p>Best of luck!</p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: '7 Ways To Have Good Email Marketing Not Break UX Design',
  url: '/blog/best-ux-practices-to-built-email-marketing-lists/',
  description: `Learn how to implement an effective email marketing strategy and not to hurt your UX design with these 4 tips!`,
  author,
  img,
  imgSocial: img,
  date: '2021-07-05',
  category: '',
  group: 'Design',
  timeToRead: 7,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
